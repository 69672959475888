//
// Custom mobile nav (unlimited subnavs possible)
// --------------------------------------------------

@media (max-width: 767px) {

  .navbar-fixed-top {
    position: relative!important;
  }

  header {
    height: auto!important;
  }

  nav .lower-nav {
    min-height: 60px!important;
    height: auto!important;
  }

  .nav-container {
    padding: 0;
  }

  #navbar-collapse {
    border-color: transparent;
    margin-right: -15px;
    margin-left: -15px;
    padding-right: 0;
    border-top: none;
    padding-left: 0;
  }

  .navbar-nav {
    margin-top: 0;
    margin-bottom: 0;
  }

  #navbar-collapse {
    max-height: inherit!important;
  }

  #navbar-collapse ul {
    list-style-type: none;
  }

  #navbar-collapse ul {
    background: #f5f6f7;
    position: relative;
    float: none;
    overflow: hidden;
    font-size: 12px;
  }

  #navbar-collapse ul li {
    position: relative;
    border-top: 1px solid #fff;
    padding: 0!important;
  }

  #navbar-collapse ul li a {
    color: #000!important;
    &:hover, &:focus, &:active {
      color: $brand-primary!important;
    }
  }

  .dropdown-toggle {
    border-bottom: none!important;
  }

  #navbar-collapse > ul > li:first-of-type {
    border: none;
  }

  #navbar-collapse ul li ul {
    padding: 0;
  }

  #navbar-collapse > ul > li {
    padding: 0;
  }

  #navbar-collapse > ul > li a {
    padding: 8px 65px 8px 10px;
    font-size: 12px;
    line-height: 20px;
    display: block;
  }

  #navbar-collapse ul.level1 {
    padding: 0;
    margin: 0!important;
  }

  #navbar-collapse ul.level2 {
    background-color: #ebeced;
    display: none;
  }

  #navbar-collapse ul.level3 {
    background-color: #e2e3e4;
    display: none;
  }

  #navbar-collapse ul.level4 {
    background-color: #d7d8d9;
    display: none;
  }

  #navbar-collapse ul.level2 a {
    padding-left: 20px;
  }

  #navbar-collapse ul.level3 a {
    padding-left: 30px;
  }

  #navbar-collapse ul.level4 a {
    padding-left: 40px;
  }

  .icon-unfold:before {
    //font-family: 'FontAwesome';
    content: "\e90d";
    color: #fff;
    font-size: 16px;
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px 9px;
    width: 55px;
    height: 37px;
    text-align: center;
    background-color: $brand-primary;
    border-bottom: 1px solid #fff;
    cursor: pointer;
    z-index: 1000;
  }

  .icon-unfold--down:before {
    content: "\e90b";
  }

}