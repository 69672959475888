#sub-page-container {
  margin-bottom: 30px;

  .garage-configurator-outer {

    h1 {
      margin-bottom: 0;
      line-height: 1.2 !important;
    }
  }
}

.garage-configurator-outer {
  .welcome-mobile {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    z-index: 10000;
    top: 0;
    padding-top: 60px;

    .welcome-header {
      height: 100px;
      overflow: hidden;
      margin-bottom: 15px;

      img {
        margin-top: -50px;
      }
    }

    .welcome-mobile--img {
      margin: 15px 0;
    }

    .btn {
      position: absolute;
      bottom: 30px;
      right: 15px;
    }
  }

  .garage-configurator-arrow {
    cursor: pointer;
    display: block;
    width: 30px;
    height: 165px;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    @media (max-width: $screen-sm-max) {
      height: 100%;
      background-color: #FFF;
      width: 20px;
    }
    &.arrow-left {
      background-image: url('/images/gc/arrow-left.png');
      background-position: center left;
      left: 15px;
      @media (max-width: $screen-sm-max) {
        left: 5px;
      }
    }
    &.arrow-right {
      background-image: url('/images/gc/arrow-right.png');
      background-position: center right;
      right: 15px;
      @media (max-width: $screen-sm-max) {
        right: 5px;
      }
    }
  }

  .garage-configurator-request {
    &.no-arrows {
      margin: 0 -30px 0 -30px;

      @media(max-width: $screen-sm-max) {
        margin: 0;
      }
    }
    padding: 25px 0 15px;
  }

  .gc-tooltip {
    position: relative;
    display: inline-block;

    .gc-tooltip-i {
      background-color: #029DE0;
      color: #FFF;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      height: 16px;
      width: 16px;
      display: inline-block;
      line-height: 16px;
      text-align: center;
      cursor: pointer;
      font-size: 12px;
    }

    .gc-tooltip-text {
      visibility: hidden;
      width: 200px;
      background-color: #029DE0;
      color: #fff;
      font-size: 12px;
      padding: 10px;
      position: absolute;
      z-index: 1;
      top: -5%;
      left: 150%;
      /*margin-left: -60px;*/
      opacity: 0;
      transition: opacity 0.3s;
    }

    .gc-tooltip-text::after {
      content: "";
      position: absolute;
      top: 13px;
      right: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent #029DE0 transparent transparent;
    }

    &:hover .gc-tooltip-text {
      visibility: visible;
      opacity: 1;
    }
  }

  h1 {
    font-size: 32px;
    line-height: inherit !important;
  }
  h2 {
    font-size: 21px;
    line-height: inherit !important;
    margin-bottom: 15px;
  }

  .gc-image-outer {
    width: 550px;
    height: 415px;
    position: relative;
  }

  .gc-image-outer .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0!important;
  }

  .gc-image-outer img.level {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
  }

  .gc-image-outer img.level.level1 {
    z-index: 100;
  }
  .gc-image-outer img.level.level2 {
    z-index: 200;
  }
  .gc-image-outer img.level.level3 {
    z-index: 300;
  }
  .gc-image-outer img.level.level4 {
    z-index: 400;
  }
  .gc-image-outer img.level.level5 {
    z-index: 500;
  }
  .gc-image-outer img.level.level6 {
    z-index: 600;
  }

  .garage-configurator-intro {
    margin-bottom: 20px;
  }

  .garage-configurator-config-outer {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    margin-top: 40px;
    left: 50%;
    box-shadow: 1px -5px 5px -5px rgba(0,0,0,0.2);

    > .container {
      position: relative;
      padding: 0 45px;

      @media(max-width: $screen-sm-max) {
        padding: 0 15px;
      }
    }
    
    .garage-configurator-config {
      overflow: hidden;

      &.no-arrows {
        margin: 0 -30px 0 -30px;

        @media(max-width: $screen-sm-max) {
          margin: 0;
        }
      }

      > strong, strong.title {
        color: #029DE0;
        font-size: 16px;
        font-weight: normal;
        display: block;
        margin: 15px 0;
      }

      .garage-configurator-config-groups-outer {
        white-space: nowrap;

        .garage-configurator-config-group {
          white-space: normal;
          display: inline-block;

          //margin-left: 20px;
          &:first-child {
            margin-left: 0px;
          }
        }
      }

      .garage-configurator-config-options {
        margin-bottom: 30px;
        width: 100%;
        //display: flex;

        .vehicle-spacer {
          display: block;
          text-align: center;
          font-style: italic;
          padding: 48px 0;
          font-size: 20px;
          color: #009DE0;
        }

        .vehicle-count-outer {
          margin-bottom: 5px;
        }

        .vehicle-count-title {
          font-size: 18px;
        }
        .vehicle-count {
          label {
            font-weight: normal;
            font-size: 12px;
            margin-bottom: 0px;
            line-height: 34px;
            input {
              position: relative;
              top: -1px;
              left: -3px;
            }
          }
        }

        .garage-configurator-config-option {
          float: left;
          margin-right: 20px;
          position: relative;

          .garage-configurator-config-option-box,
          .garage-configurator-config-option-color,
          .garage-configurator-config-option-image {
            font-size: 0px;
            padding: 4px;
            width: 122px;
            height: 65px;
            border: 1px solid #C9C8C9;
            vertical-align: middle;
            display: table-cell;
            text-align: center;
            &:hover,
            &.active {
              background-color: #009de0;
            }
          }

          .garage-configurator-config-option-image {
            padding: 10px 15px;
            > svg {
              width: auto;
              height: auto;
              max-width: 90px;
              max-height: 43px;
            }

            &.image_file {
              padding: 0;
            }
          }
          .garage-configurator-config-option-box {
            > svg {
              height: 55px;
              width: 110px;
            }
          }

          .garage-configurator-config-option-color {
            >span {
              width: 100%;
              height: 100%;
              display: block;
            }
          }

          .garage-configurator-config-option-title {
            text-align: center;
            color: #333;
            > span {
              display: none;
            }
          }

          .garage-configurator-config-option-zoombtn {
            position: absolute;
            left: 5px;
            top: 5px;
            background-color: rgba(255, 255, 255, .8);
            padding: 5px 7px;
            i {
              font-weight: bold;
            }


            &:hover {
              background-color: #009de0;
              color: #FFF;
            }
          }
        }
      }

    }
  }

  .flex-container {
    display: flex;
  }

  .garage-configurator-steps-outer {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: 50%;
    border-top: 1px solid #009de0;

    .flex-container {
      justify-content: space-between;
      margin-left: -2px;
      margin-right: -2px;
    }

    .garage-configurator-step-outer {
      //float: left;
      width: 100%;
      margin: 0 2px;
      padding: 15px 0;
      text-align: center;
      color: #009de0;
      font-size: 24px;
      > span {
        color: #333;
        font-size: 14px;
        display: block;
      }

      &.active,
      &:hover {
        background-color: #009de0;
        color: #FFF;
        span {
          color: #FFF;
        }
      }

      &:hover {
        svg {
          path {
            fill: #FFF;
            stroke: #FFF;
          }
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }


  .garage-configurator-current-configuration-outer {
    .btn__openconfig {
      font-weight: bold;
      margin-bottom: 10px;
    }

    .garage-configurator-current-configuration {
      padding: 10px;
      border: 1px solid #009de0;
      margin-bottom: 10px;
      height: 150px;
      overflow-y: scroll;

      > ul {
        padding-left: 0px;
        list-style-type:none;
        > li:before {
          content: "-";
          padding-right: 5px;
        }
      }
    }
    button {
      background-color: #009de0;
      color: #FFF;
      border: 1px solid #009de0;
      padding: 15px 30px;
      width: 100%;
      margin: 5px 0;
      &:hover {
        background-color: #FFF;
        color: #009de0;
      }

      &.btn-danger {
        background-color: #9b2321;
        border: 1px solid #9b2321;
        padding: 7.5px;

        &:hover {
          background-color: #FFF;
          color: #9b2321;
        }
      }
    }
  }

  .break-row {
    flex-basis: 100%;
    height: 0;
  }

  .custom-radio {
    display: block;
    position: relative;
    padding-left: 20px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
  }

  /* Hide the browser's default checkbox */
  .custom-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 7px;
    left: -3px;
    height: 20px;
    width: 20px;
    border: 1px solid #009de0;
    border-radius: 100%;
  }

  /* On mouse-over, add a grey background color */
  .custom-radio:hover input ~ .checkmark {
    //background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .custom-radio input:checked ~ .checkmark {
    //background-color: #2196F3;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .custom-radio input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .custom-radio .checkmark:after {
    left: 4px;
    top: 4px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #2196F3;
  }

  .form-control {
    border-radius: 0;
  }

  .uislider-container {
    padding: 0 15px;

    @media(max-width: $screen-sm-max) {
      padding: 0;
    }
  }
}


@media (min-width: 768px) {

  .toggle-container.collapse {
    display: block!important;
  }

  .row-xs {
    float: left;
    margin: 0;
  }
}

@media (max-width: 1199px) {

}

@media (max-width: 991px) {

  .gc-image-outer.pull-right {
    float: none!important;
    width: 100%;
    height: auto;
    padding-bottom: 60%;
    margin-top: 20px;
  }

  .garage-configurator-outer .garage-configurator-steps-outer .garage-configurator-step-outer span {
    font-size: 12px;
  }
}

@media (max-width: 767px) {

  .ctl--garage-configurator .garage-configurator-outer {
    margin-top: -40px;
    padding-bottom: 10px;
  }

  .garage-configurator-outer .garage-configurator-steps-outer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 9999;
    margin: 0;
  }

  .garage-configurator-outer .garage-configurator-steps-outer .flex-container {
    overflow-x: scroll;
  }

  .garage-configurator-outer .garage-configurator-steps-outer .garage-configurator-step-outer {
    width: 80px;
    min-width: 80px;
    margin: 0;
    height: 56px;
    padding: 3px 0 0 0;
    font-size: 19px;
  }

  .garage-configurator-outer .garage-configurator-intro {
    margin-bottom: 0;
  }

  .garage-configurator-outer .garage-configurator-current-configuration-outer .btn__openconfig {
    position: relative;
    padding: 10px 0;
    margin: 0;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      right: 10px;
      top: 10px;
      background-image: url("../images/gc/arrow-down.svg");
      height: 19px;
      width: 19px;
      display: block;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  .toggle-container {
    //display: none;

    button {
      margin-bottom: 20px;
    }
  }

  .garage-configurator-outer .garage-configurator-config-outer {
    margin-top: 0;
  }

  .uislider-container {
    height: 50px;
    width: 85%;
    margin: 0 7.5%;
  }
  
  .garage-configurator-outer .garage-configurator-config-outer .garage-configurator-config .garage-configurator-config-options .vehicle-spacer {
    display: none;
  }

  .garage-configurator-outer .garage-configurator-config-outer .garage-configurator-config .garage-configurator-config-options .vehicle-count-title {
    font-size: 12px;
    white-space: nowrap;
    line-height: 34px;
    margin: 15px 0 10px 0;
  }

  .submit_garage-configurator {
    margin-bottom: 30px;
  }

  .garage-configurator-config-option {
    margin-bottom: 10px;
  }

  .garage-configurator-config-option-title {
    font-size: 10px;
    min-height: 28px;
  }

  .garage-configurator-outer .garage-configurator-config-outer .garage-configurator-config strong.title {
    font-size: 14px;
  }
}

@media (max-width: 991px) {

  .uislider-container {
    height: 50px;
    width: 90%;
    margin: 0 5%;
  }

}