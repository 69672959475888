@font-face {
  font-family: 'icomoon-attributes';
  src:  url('../fonts/icomoon-attributes.eot?pmfkl3');
  src:  url('../fonts/icomoon-attributes.eot?pmfkl3#iefix') format('embedded-opentype'),
  url('../fonts/icomoon-attributes.ttf?pmfkl3') format('truetype'),
  url('../fonts/icomoon-attributes.woff?pmfkl3') format('woff'),
  url('../fonts/icomoon-attributes.svg?pmfkl3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="attribute-"], [class*=" attribute-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-attributes' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.attribute-1:before {
  content: "\31";
}
.attribute-2:before {
  content: "\32";
}
.attribute-3:before {
  content: "\33";
}
.attribute-4:before {
  content: "\34";
}
.attribute-pkw:before {
  content: "\63";
}
.attribute-farben:before {
  content: "\66";
}
.attribute-eben:before {
  content: "\65";
}
.attribute-strukturiert:before {
  content: "\73";
}
.attribute-formatmix:before {
  content: "\6d";
}
.attribute-gecurlt:before {
  content: "\67";
}
.attribute-alt:before {
  content: "\61";
}
.attribute-kristall:before {
  content: "\6b";
}
.attribute-terasse:before {
  content: "\74";
}
.attribute-duplex:before {
  content: "\64";
}
.attribute-lkw:before {
  content: "\6c";
}

.fieldset {
  position: relative;
  border: 1px solid #cacaca;
  padding: 30px 15px 5px 15px;
  margin-bottom: 50px;
}

.fieldset-title {
  position: absolute;
  top: 0;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 1;
  margin: -9px -10px 0; /* half of font-size */
  background: #fff;
  padding: 0 10px;
  color: $brand-primary;
  font-weight: 600;
}

.fieldset-row {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.fieldset-row:after, .fieldset-row:before {
  content: "";
  display: table;
}

.fieldset-col {
  width: 50%;
  padding-left: 7.5px;
  padding-right: 7.5px;
  float: left;
}

.attribute {
  margin-bottom: 0;
}

.produkteigenschaften .attribute {
  margin-bottom: 20px;
}

.attribute__box {
  border: 1px solid #cacaca;
  height: 65px;
  margin-bottom: 5px;
  //padding: 5px;
  overflow: hidden;
}

.abmessungen .attribute__box {
  height: auto;
}

.abmessungen .attribute__name {
  line-height: 34px;
}

.attribute__icon {
  font-size: 59px;
  text-align: center;
  display: block;
  position: relative;
  transform: translateY(-50%);
  top: 50%;

  img {
    width: 90%;
    margin-top: -14px;
  }

}

//.attribute__icon--image {
//  display: block;
//  background-repeat: no-repeat;
//  background-size: cover;
//  background-position: center center;
//  width: 100%;
//  height: 100%;
//}

.attribute__color {
  display: block;
  height: 100%;
  height: 84%;
  width: 90%;
  margin: 5px auto;
}

.attribute__box--unsupported {
  color: #bfbfbf;
}

.attribute__name {
  text-align: center;
  display: block;
  font-size: 11px;
  font-family: Arial;
  min-height: 34px;
}


/* Kleine Geräte (Tablets, 768px und breiter) */
@media (min-width: $screen-tablet) {
  .fieldset-col {
    width: 25%;
  }
  .attribute__box {
    height: 85px;
  }

  .abmessungen .attribute__box {
    height: auto;
  }
}


/* Mittlere Geräte (Desktop-PCs, 992px und breiter) */
@media (min-width: $screen-desktop) {
  .fieldset-col {
    width: 16.66667%;
  }
}

/* Große Geräte (Desktop-PCs, 1200px und breiter) */
@media (min-width: $screen-lg-desktop) {
  .fieldset-col {
    width: 16.66667%;
  }
  .attribute__box {
    height: 65px;
  }

  .abmessungen .attribute__box {
    height: auto;
  }
}