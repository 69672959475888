// Custom (non-bootsrap) Project Styles - must be imported first in case of SASS
@import "jquery.fancybox.scss";
@import "connectiv/z-index.scss";
@import "connectiv/sprite.scss";
@import "connectiv/custom-vars.scss";
@import "connectiv/conn-basics.scss";
@import "connectiv/fonts.scss";
@import "connectiv/custom-mobile-nav.scss";
// Font-Awesome
// Wenn nur einzelne Icons verwendet werden, diese als SVG extrahieren (z.B. mit der Chrome-App "IcoMoon")
//@import "font-awesome/font-awesome.scss";
//Bootstrap
//enthält in dieser Einstellung nur häufig benötigte Dateien (bei Bedarf zusätzliche Kompontenten in der _bootstrap.scss einkommentieren)
@import "bootstrap/bootstrap";
@import "connectiv/attributes.scss";
@import "connectiv/forms.scss";
//Slick Slider
@import "slick/slick.scss";
//NoUI Slider
@import "connectiv/nouislider.scss";

//Bootstrap-Datepicker
@import "../../node_modules/bootstrap-sass-datepicker/sass/datepicker.scss";

.page-content > .-webcms-component > .text {
  margin-top: 0!important;
}

.sticky-bar {
  display: none;
}

.panel {
  box-shadow: none!important;
}

.panel-heading .btn-inline {
  margin-right: 15px;
  margin-top: 5px;
  line-height: 1;
  font-weight: 700;
  border-color: #ddddff;
  padding: 6px 20px;
  color: #fff;
}

.timeline-outer {
  margin-top: 20px;
  max-width: 650px;
}

.timeline-item {
  margin-bottom: 10px;
}

.timeline-item-text {
  border-left: 1px solid #ccc;
  padding-left: 20px;
}

.contactperson__item {
  margin-bottom: 40px;
}

.privacy-policy-link:hover {
  color: #fff!important;
}

.slick-slide:focus {
  outline: none;
}

.slick-arrow {
  position: absolute;
  border: none;
  background: transparent;
  font-size: 0;
  width: 44px;
  height: 44px;
  background-repeat: no-repeat;
  background-position: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  opacity: 0.5;
  transition: all 0.3s;
  outline: none;
}

.slick-arrow:hover {
  opacity: 1;
}

.slick-prev {
  left: 0;
  background-image: url('../img/angle-left.svg');
}

.slick-next {
  right: 0;
  background-image: url('../img/angle-right.svg');
}

.slick-dots {
  position: absolute;
  width: 100%;
  text-align: center;
  padding: 0;
}

.slick-dots li {
  list-style-type: none;
  display: inline-block;
}

.slick-dots li button {
  border: none;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  font-size: 0;
  margin: 5px;
  background-color: $brand-primary;
  opacity: 0.5;
}

.slick-dots li.slick-active button {
  opacity: 1;
}

.slick-dots li button:hover {
  opacity: 0.75;
}

.content-slider .slick-prev {
  left: -28px
}

.content-slider .slick-next {
  right: -28px
}

.content-slider {
  margin: 0 -$grid-gutter-width/2;
}

.content-slider--item {
  margin: 0 $grid-gutter-width/2;
}

//Animatiertes Menüicon
.menu-icon {
  width: 44px;
  height: 44px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  background: none;
  border: none;
}

.menu-icon span {
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  background: $brand-primary;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.menu-icon span:nth-child(1) {
  top: 7px;
}

.menu-icon span:nth-child(2), .menu-icon span:nth-child(3) {
  top: 19px;
}

.menu-icon span:nth-child(4) {
  top: 31px;
}

.menu-icon.open span:nth-child(1) {
  top: 18px;
  width: 0;
  left: 50%;
}

.menu-icon.open span:nth-child(2) {
  transform: rotate(45deg);
}

.menu-icon.open span:nth-child(3) {
  transform: rotate(-45deg);
}

.menu-icon.open span:nth-child(4) {
  top: 18px;
  width: 0;
  left: 50%;
}

.menu-icon:focus {
  outline: none;
}

nav ul {
  margin: 0;
  padding: 0;
}

nav ul li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@mixin font-size($sizeValue: 1.6) {
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
}

@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?zanky8');
  src: url('../fonts/icomoon.eot?zanky8#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?zanky8') format('truetype'),
  url('../fonts/icomoon.woff?zanky8') format('woff'),
  url('../fonts/icomoon.svg?zanky8#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow_left:before {
  content: "\e900";
}

.icon-arrow_right:before {
  content: "\e901";
}

.icon-search:before {
  content: "\e902";
}

.icon-download:before {
  content: "\e903";
}

.icon-youtube-play:before {
  content: "\e904";
}

.icon-twitter:before {
  content: "\e905";
}

.icon-google-plus:before {
  content: "\e906";
}

.icon-facebook:before {
  content: "\e907";
}

.icon-facebook-f:before {
  content: "\e907";
}

.icon-phone:before {
  content: "\e908";
}

.icon-envelope:before {
  content: "\e909";
}

.icon-angle-up:before {
  content: "\e90a";
}

.icon-angle-down:before {
  content: "\e90b";
}

.icon-angle-left:before {
  content: "\e90c";
}

.icon-angle-right:before {
  content: "\e90d";
}
.icon-insta:before {
  content: "\e900";
}
.icon-calendar:before {
  content: "\e953";
}

.dropdown-icon {
  position: absolute;
  display: block;
  width: 44px;
  height: 44px;
  right: 0;
  top: 3px;
  transition: all 0.3s;
}

.dropdown-icon.rotate {
  transform: scaleY(-1);
}

.dropdown-icon img {
  fill: #FFF;
  transition: transform 0.3s;
}

/* ==========================================================================
   Project's custom styles
   ========================================================================== */

$mobile-menu-height: 60px;

.container-fluid {
  max-width: 1140px + $grid-gutter-width;
}

*:focus {
  outline-color: $brand-primary;
}

body {
  @include font-size(1.4);
  font-family: 'Open Sans', sans-serif;
}

h1, h2, h3, h4 {
  margin-top: 0;
}

h1 {
  @include font-size(2.4);
}

h2 {
  @include font-size(2.0);
}

h3 {
  @include font-size(1.6);
}

p {
  @include font-size(1.4);
}

input {
  border-radius: 0 !important;
  box-shadow: none !important;
}

header {
  position: relative;
  height: 60px;
  //width: 100%;
  //background: #FFF;
  //z-index: 9999;
}

.navbar-default {
  border-bottom: none;
}

.navbar-toggle {
  margin: 13px 0;
  border: none;
  padding: 5px 0;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: $brand-secondary;
  width: 36px;
  height: 4px;
}

.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
  background: none;
}

.navbar-toggle .icon-bar+.icon-bar {
  margin-top: 6px;
}

#navbar-collapse {
  background: $brand-primary;
  border: 1px solid $brand-secondary;
  //border-top: 5px solid $brand-secondary;
  margin: 0 -15px;
}

#navbar-collapse li a {
  color: #FFF!important;
  font-weight: bold;
  padding-left: 0;
}

nav .navbar-collapse input {
  height: 44px;
  border: none;
}

nav .navbar-collapse button[type="submit"] {
  height: 44px;
  border: none;
  width: 15%;
  padding: 0;
}

nav .navbar-collapse button[type="submit"] i {
  font-size: 24px;
  font-weight: bold;
  line-height: 44px;
}


.btn {
  line-height: 1.42857143; //fixes SASS number precision issue
  border-radius: 0;
}

.search {
  position: relative;
}

.search form {
  margin: 10px;
}

.search input {
  width: 100%;
  height: 44px;
  border: none;
  padding: 0 50px 0 10px;
}

.search--btn {
  position: absolute;
  top: 0;
  right: 10px;
  padding: 0;
  height: 44px;
  width: 44px;
  border: 0;
  background-color: transparent !important;
}

.search--btn img {
  height: 24px;
}

nav .upper-nav {
  font-size: 12px;
}

nav .upper-nav a {
  color: #000;
  font-weight: 600;
}

nav .upper-nav li {
  display: inline-block;
  padding: 10px 0;
}

nav .upper-nav li:first-child:after {
  padding: 0 6px;
  content: " | ";
}

nav .upper-nav li:last-child {
  margin-left: 30px;
}

nav .upper-nav .dropdown {
  display: block;
}

nav .upper-nav .dropdown-menu {
  min-width: 80px;
  text-align: center;
  border-radius: 0;
}

nav .upper-nav .dropdown-menu li {
  display: block;
  padding: 0;
}

nav .upper-nav .dropdown-menu li:first-child:after {
  padding: 0;
  content: "";
}

nav .upper-nav .dropdown-menu li:last-child {
  margin-left: 0;
}

nav .lower-nav {
  height: 60px;
  //border-bottom: 5px solid $brand-secondary;
}

nav .navbar-brand {
  padding: 13px 0;
  margin-left: 0 !important;
  height: 60px;
  width: 220px;
  display: block;
}

nav .navbar-collapse {
  float: none;
}

nav .navbar-collapse a {
  color: #000 !important;
  transition: all 0.3s;
}

.right-content .iconstrip__img {
  margin-left: 50%!important;
  height: 48px!important;
  width: auto!important;
}

.slider-new {
  border: none!important;
  margin-top: 30px;
  position: relative;
  margin-bottom: 20px;
}

.slider-new .lg-slider--item {
  background-color: #5e51486e;
}
.slider-new a {
  position: relative;
  display: block;
  padding-bottom: 56%;
}

.slider-new img {
  height: 100%;
  position: absolute!important;
  object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border: none!important;
  margin: 0!important;
}

.slider-new .lg-slider--item {
  height: auto;
}

.slider-new  .slick-next {
  margin-left: 342px!important;
  top: 89%!important;
}

.slider-new .slick-prev {
  margin-left: 275px!important;
  top: 89%!important;
}

.slider-new .slider-medium .slick-prev,
.slider-new .slider-medium .slick-next {
  display: block !important;
  position: absolute;
  border: 1px solid #FFF;
  width: 58px;
  height: 58px;
  top: 73%;
  left: 50%;
}

.slider-new .slider-medium .slick-prev {
  background-image: none;
  margin-left: 272px!important;
  top: 90%!important;
}


.slider-new .slider-medium .slick-arrow {
  font-size: 40px;
  color: #FFF;
}

#sub-page-container  .slider-new ul {
  margin-top: -34px;
}

#sub-page-container  .slider-new ul li button {
  background-color: #fff;
  padding: 0;
  width: 10px;
  height: 10px;
}


.section-header_slider img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  //left: 50%;
  height: auto;
  width: 100%;
  border-top: none!important;
}


@media (min-width: 768px) {

  .section-header_slider .slick-list {
    border-top: 5px solid #5e5148;
  }

  #navbar-collapse li a:hover {
    color: #FFF !important;
    background-color: $brand-primary !important;
  }

  .slider-new .lg-slider--item {
    height: auto;
  }

}

nav .navbar-collapse li {
  position: relative;
  padding: 5px 5px 5px 15px!important;
  font-size: 18px;
}

nav .navbar-collapse .dropdown {
  display: block !important;
}

nav .navbar-collapse .active {
  font-weight: 600;
  //font-style: italic;
}

nav .navbar-collapse input, button {
  display: inline;
}

nav .navbar-collapse input {
  width: 85%;
}

.sub-nav {
  background-color: $brand-primary;
  color: #fff;
  padding: 35px 25px 40px;
}

.sub-nav a.active {
  font-weight: 600;
  //font-style: italic;
}

.sub-nav ul {
  list-style-type: none;
  padding-left: 0;
  font-size: 16px;
}

.sub-nav ul li {
  margin-bottom: 18px;
}

.sub-nav ul a {
  color: #FFF;
}

.sub-nav ul ul {
  font-size: 14px;
  margin-top: 15px;
  padding-left: 13px;
}

.sub-nav ul ul li {
  text-transform: none;
  margin-bottom: 16px;
}

.sub-nav ul ul ul {
  font-size: 13px;
  padding-top: 15px;
  //padding-left: 23px;
}

.sub-nav ul ul ul li {
  margin-bottom: 18px;
}

.section-header_slider {
  position: relative;
  margin-bottom: 20px;
  border-top: 5px solid $brand-secondary;
}

.section-header_slider .slider--item {
  height: 125px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.section-header_slider .slick-prev,
.section-header_slider .slick-next {
  display: none !important;
}

.slider-new .slick-prev,
.slider-new .slick-next {
  display: none !important;
}

.section-header_slider .mask {
  position: absolute;
  width: 100%;
  height: 68px;
  bottom: -1px;
  background-image: url("../img/mask_inside.svg");
  background-size: cover;
  background-position-x: -775px;
}

.section-teaser {
  padding-bottom: 15px;
}

.section-teaser .teaser-preview {
  position: relative;
  background-color: $brand-primary;
  padding: 10px;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  height: 310px;
  margin-bottom: 20px;
  max-width: 100%;
}

.section-teaser .teaser-img {
  height: 160px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 10px;
}

.section-teaser p {
  position: relative;
  margin: 0;
}

.section-teaser a {
  color: #FFF;
}

.section-teaser i {
  padding-right: 5px;
}

.section-teaser .teaser-headline {
  max-height: 40px;
  overflow: hidden;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 10px;
}

.section-teaser .teaser-text {
  max-height: 40px;
  overflow: hidden;
}

.section-teaser .teaser-link {
  position: absolute;
  height: 19px;
  top: 90%;
}

.section-themepic {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 35px;
  background-color: #f7f5f6;
}

.themepic-wrapper {
  height: 150px;
  background-size: cover;
  background-position: center center;
  margin: 0 10px;
}

.section-themepic .heading,
.section-themepic .text,
.section-themepic a {
  position: relative;
  z-index: 100;
  color: #fff;
  width: 100%;
  min-height: 50px;
  text-align: center;
}

.section-themepic .heading {
  background-color: $brand-primary;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 300;
  padding: 7px 15px;
}

.section-themepic .text {
  background-color: $brand-secondary;
  font-size: 14px;
  margin: 0;
  padding: 15px 15px;
}

.themepic-link {
  padding: 15px!important;
  font-size: 16px;
}

.section-themepic a {
  background-color: $brand-primary;
  min-height: 35px;
}

.section-themepic span {
  font-size: 8px;
  vertical-align: middle;
}

.section-themepic i {
  font-size: 10px;
  padding-right: 3px;
}

.section-news_slider {
  width: 100%;
  background-color: $brand-primary;
  padding-bottom: 30px;
}

.section-news_slider .container {
  padding-top: 10px;
  padding-bottom: 10px;
}

.section-news_slider h2 {
  color: #fff;
  text-transform: uppercase;
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.section-news_slider .news-slider--item {
  background-color: #fff;
  margin-bottom: 15px;
  width: 100%;
  height: 160px;
}

.section-news_slider .pull-right {
  padding: 17px 10px;
}

.section-news_slider img {
  width: 160px;
  height: 160px;
}

.section-news_slider .text p {
  font-size: 14px;
  margin: 0;
}

.section-news_slider a {
  font-size: 14px;
  max-width: 257px;
  color: $brand-primary;
  vertical-align: bottom;
}

.section-news_slider .heading {
  font-weight: 600;
  max-height: 57px;
  overflow: hidden;
  margin-bottom: 10px;
}

.news-slider--text {
  height: 38px;
  overflow: hidden;
  margin-bottom: 10px;
}

.section-news_slider .all-news {
  background-color: $brand-secondary;
  color: #FFF;
  display: block;
  font-size: 18px;
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
  width: 100%;
  max-width: none;
}

.section-news_slider .all-news p {
  color: #fff;
  text-transform: uppercase;
  font-size: 28px;
  text-align: center;
  line-height: 130%;
  padding: 10px;
}

.section-news_slider .all-news b {
  font-size: 52px;
}

footer {
  background-color: $brand-secondary;
  color: #fff;
  padding: 30px 10px 10px 10px;
}

footer ul {
  display: inline-block;
  vertical-align: top;
  list-style-type: none;
  padding: 0;
}

footer ul:first-of-type {
  margin-right: 20%;
}

footer li {
  padding-bottom: 7px;
}

.footer-logos a img {
  margin-bottom: 10px;
}

.footer-logos a:not(:last-of-type) img {
  margin-right: 10px;
}

footer a {
  color: #FFF;
}

footer .icon-wrapper {
  margin-top: 20px;
  margin-bottom: 30px;
}

footer i {
  font-size: 20px;
  margin-right: 15px;
}

footer .email {
  margin-top: -5px;
}

footer .copy {
  font-size: 12px;
  margin-top: 20px;
}

.footer-menu {
  margin-top: 20px;
  margin-bottom: 20px;
}

.header-sub_pages {
  position: relative;
  margin-top: 0;
  width: 100%;
  height: 90px;
  border-top: 5px solid $brand-secondary;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
}

#sub-page-container {
  position: relative;
  z-index: 1;
  margin-top: -40px;
  margin-bottom: 70px;
}


#sub-page-container .text img{
  height:auto!important
}

#sub-page-container .text img{
  height:auto!important
}

#sub-page-container .left-content {
  float: left;
  width: 22%;
  margin-right: 3.8%;
}

#sub-page-container .right-content {
  float: left;
  width: 100%;
}

#sub-page-container .mail,
#sub-page-container .phone {
  background-color: $brand-secondary;
  width: 100%;
  min-height: 53px;
  color: #fff;
  padding: 5px;
}

#sub-page-container .mail span,
#sub-page-container .phone span {
  font-size: 24px;
  position: relative;
  top: 8px;
  margin-left: 13px;
}

#sub-page-container p {
  line-height: 1.2;
}

#sub-page-container b {
  font-size: 13px;
}

#sub-page-container .phone {
  margin-top: 15px;
}

#sub-page-container .phone span {
  top: 10px;
}

#sub-page-container .breadcrumb {
  display: inline-block;
  background-color: $brand-primary;
  border-radius: 0;
  padding: 13px;
  height: 40px;
  font-size: 12px;
  text-transform: uppercase;
}

#sub-page-container .breadcrumb li {
  padding: 0 6px;
  a {
    color: #fff!important;
  }
}

#sub-page-container a .active {
  color: #CCC;
}

#sub-page-container .page-content {
  float: left;
  width: 100%;
  margin-top: 50px;
}

#sub-page-container .page-content h1 {
  color: $brand-primary;
  text-transform: uppercase;
  line-height: 1.5;
}

#sub-page-container .page-content p {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 18px;
}

#sub-page-container .text {
  margin-top: 29px;
}

#sub-page-container .text p {
  margin-top: 10px;
}

#sub-page-container .text p:first-of-type {
  margin-top: 15px;
}

#sub-page-container .text i {
  padding-right: 5px;
}

#sub-page-container .text ul {
  padding-left: 25px;
  color: $brand-primary;
}

#sub-page-container .text ul a {
  text-decoration: underline;
}

#sub-page-container .text-img img {
  float: right;
  //margin: -10px 35px;
  margin-left: 30px;
}

//#sub-page-container .img-text {
//  margin-top: 25px;
//}

#sub-page-container .img-text img {
  float: left;
  margin-right: 30px;
}

#sub-page-container .img-preview {
  margin: 25px 0;
}

#sub-page-container .lg-slider {
  display: block;
  width: 100%!important;
  margin-bottom: 10px;
}

.lg-slider--item {
  position: relative;
  height: 200px;
  height: 55vw;
  background: #eee;
}

.lg-slider--item img {
  /*position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);*/
}



#sub-page-container .sm-slider {
  display: inline-block;
  vertical-align: top;
  float: left;
  width: 65%;
}

.sm-slider--item {
  position: relative;
  margin-right: 10px;
  height: 60px;
  background: #eee;
  overflow: hidden;
  transition: box-shadow 0.3s;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border: 2px solid transparent;
  transition: border 0.3s;
}

.sm-slider--item.slick-current {
  border: 2px solid $brand-primary;
}

.sm-slider--item img {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.sm-slider-arrows {
  position: relative;
  float: left;
  width: 35%;
  height: 60px;
}

.sm-slider-arrows .slick-arrow {
  background: $brand-primary;
  opacity: 1;
  height: 60px;
  width: 48%;
  font-size: 14px;
}

.sm-slider-arrows .slick-arrow i {
  color: #FFF;
  font-size: 38px;
  transform: rotate(90deg);
  display: block;
}

.section-news_slider .slick-dots {
  margin-top: -15px;
}

.section-news_slider .slick-dots button {
  margin-top: 15px;
  background-color: #fff;
  border-radius: 0;
  width: 12px;
  height: 12px;
  opacity: 1;
  padding: 0;
  border: 1px solid #fff;
}

.section-news_slider .slick-active button {
  background-color: $brand-secondary;
}

#news-slider {
  padding-bottom: 30px;
}

.section-header_slider .headline,
.section-header_slider .sub-headline {
  display: inline-block;
  padding: 3px 10px;
  color: #FFF;
  z-index: 100;
  margin: 0
}

.section-header_slider .headline {
  background-color: $brand-primary;
  font-size: 16px;
  font-weight: 300;
  text-transform: uppercase;
}

.section-header_slider .sub-headline {
  background-color: $brand-secondary;
  font-size: 12px;
  font-weight: 300;
  margin-top: 3px;
}

.slider-overlay {
  position: absolute;
  bottom: 10px;
}

.footer-menu a {
  position: relative;
}

.footer-menu a:hover {
  color: #FFF;
}

.footer-menu a:hover:before {
  font-family: icomoon;
  content: "\e901";
  display: block;
  position: absolute;
  left: -10px;
  font-size: 8px;
  top: 4px
}

.icon-wrapper a i {
  transition: transform 0.3s;
  display: inline-block;
}

.icon-wrapper a:hover i {
  color: #FFF;
  transform: scale(1.5);
}

.standort-item {
  background-color: #eee;
  padding: 10px;
  margin-bottom: 10px;
}

.additional-info > div {
  display: none;
  padding-bottom: 10px;
}

.additional-info > button {
  border: none;
  background: $brand-primary;
  color: #FFF;
  padding: 5px 20px;
  width: 100%;
  text-align: center;
}

.additional-info table tr {
  border-top: 2px solid #eee;
  border-bottom: 2px solid #eee;
}

.additional-info table td:first-of-type {
  background-color: $brand-secondary;
  color: #FFF;
  padding: 5px 10px;
}

.additional-info table td:last-of-type {
  padding-left: 10px;
}

//.right-content .page-content a {
//  color: $brand-primary;
//}

.pdf-downloads {
  margin: 0;
  padding: 0;
}

.pdf-downloads li {
  list-style: none;
  padding-bottom: 8px;
}

.pdf-downloads li:before {
  font-family: icomoon;
  content: "\e903";
  color: $brand-secondary;
  padding-right: 10px;
  position: relative;
  top: 2px;
}

.search-header {
  background-color: #eee;
  border-bottom: 5px $brand-secondary solid;
  padding: 50px 20px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.search-header input {
  -webkit-box-shadow: inset 0px 0px 10px 4px rgba(235,235,235,1);
  -moz-box-shadow: inset 0px 0px 10px 4px rgba(235,235,235,1);
  box-shadow: inset 0px 0px 10px 4px rgba(235,235,235,1);
  border: 0;
  width: 100%!important;
  height: 40px;
  padding: 10px 20px;
  font-size: 1em;
  margin: 20px 0;
}

.search-header button[type="submit"] {
  background-color: #009de0;
  border: 0;
  width: 100%;
  height: 40px;
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  text-align: left;
}

.search-header .form-inline {
  max-width: 500px;
}

.sfe_content .in nav {
  text-align: right;
}

.sfe_content .in .pagination a.active {
  color: #FFF!important;
}

.pagination>li:first-child>a, .pagination>li:first-child>span,
.pagination>li:last-child>a, .pagination>li:last-child>span {
  border-radius: 0;
}

.sfe_content .in .list-group p {
  color: $brand-secondary;
}

.sfe_content .in .list-group a:not(:last-of-type) {
  display: block;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.navbar-default .navbar-nav>.active>a {
  background: none;
}

.navbar-default .navbar-nav > li > a {
  display: inline-block;
}

#navbar-collapse .sub-nav {
  display: none;
}

.sub-nav ul a {
  font-weight: normal!important;
}

.sub-nav ul li.active > a {
  font-weight: 600!important;
}

.dropdown-icon {
  border: 1px solid #FFF;
  text-align: center;
  margin-right: 10px;
}

.sub-nav .dropdown-icon {
  margin-right: 5px;
}

.navbar-fixed-top {
  border-bottom: 1px solid $brand-secondary;
}

table.table th, table.table td {
  border: 1px #CCC solid;
}

table.table th {
  background-color: #F5F5F5;
}

.header-image-wrapper {
  height: 90px;
  overflow: hidden;
  position: relative;
}

.header-image-wrapper > div {
  transform: translate(-50%,-50%);
  position: relative;
  top: 50%;
  left: 50%;
}

.image-gallery__item {
  margin-bottom: 30px;
}
.image-gallery__item-img-new {
  height: 200px!important;
  border:none!important;
}
.image-gallery__item-img {
  height: 250px;
  border: 1px solid #cacaca;
  > .img-responsive {
    display: block;
    position: relative;
    max-height: 100%;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    z-index: -1;
  }
}

.gallery__item-title {
  font-weight: bold;
  margin-top: 10px;
}

.gallery__item-text {
  margin-top: 5px;
  margin-bottom: 15px;
}

.seperation-line {
  display: block;
  border-bottom: 1px solid #eee;
  margin-top: 15px;
  margin-bottom: 15px;
}

#navbar-collapse ul li.lang-select-mobile {
  font-size: 12px!important;
  padding-left: 10px!important;
  padding-right: 10px!important;
  text-align: right!important;
}

#navbar-collapse ul li.lang-select-mobile a {
  display: inline-block;
  padding: 8px 10px 8px 10px!important;
}

#navbar-collapse ul li.lang-select-mobile a.active {
  color: $brand-primary!important;
}
.section-iconstrip .container {
  max-width: 100% !important;
}

@media (max-width: 767px) {
  .image-gallery__item-img-new {
    height: 250px!important;
  }
  .sub-nav {
    padding: 5px 0 10px 0;
  }

  nav .navbar-collapse .sub-nav li {
    padding-right: 0!important;
  }

  .sub-nav li a {
    display: inline-block;
    font-weight: 400!important;
    padding: 8px 0 7px 0;
  }

  .sub-nav ul li {
    margin-bottom: 0!important;
  }

  .sub-nav ul ul {
    margin-top: 0;
    padding-left: 0;
  }

}
@media (max-width: 991px) {
  .image-gallery__item-img-new {
    height: 120px;
  }
}

/* Kleine Geräte (Tablets, 768px und breiter) */
@media (min-width: $screen-tablet) {
  .section-iconstrip {
    margin: 15px 0!important;
  }
  .seperation-line {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .navbar-fixed-top {
    border-bottom: none;
  }

  .navbar-default .navbar-nav>.active>a {
    background: #eee;
  }

  header {
    height: 100px;
  }

  .navbar-fixed-top {
    position: relative;
  }

  nav .lower-nav {
    height: 78px;
    padding-top: 15px;
  }

  .navbar-fixed-top.shrink .lower-nav {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #FFF;
    border-bottom: 2px solid $brand-secondary;
  }

  .navbar-default {
    height: 100px;
  }

  #navbar-collapse {
    background: none;
    border: none;
  }

  #navbar-collapse li a {
    color: $brand-secondary!important;
    padding: 13px 10px;
    font-size: 16px;
  }

  nav .upper-nav li {
    padding: 10px 0 0 0;
  }

  nav .navbar-brand {
    width: 250px;
    padding: 5px 0 0 0;
    height: auto;
  }

  nav .navbar-collapse {
    float: right;
  }

  nav .navbar-collapse .dropdown {
    position: static;
  }

  nav .navbar-collapse li {
    padding: 0 !important;
  }

  nav .navbar-collapse li:last-child {
    position: relative;
    width: 60px;
    margin-left: 25px;
  }

  nav .navbar-collapse i {
    display: block;
    padding: 10px 16px;
    color: #fff;
    font-size: 26px;
  }

  nav .navbar-collapse .dropdown {
    width: 60px;
    height: 46px;
    background-color: $brand-secondary;
  }

  nav .navbar-collapse .dropdown-menu {
    top: 57px;
    right: 0;
    left: auto;
    width: 300px;
    border-radius: 0;
    padding: 0;
  }

  nav .navbar-collapse .dropdown-menu:before {
    content: "";
    display: block;
    top: -12px;
    right: 14px;
    width: 0;
    height: 0;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-bottom: 12px solid $brand-secondary;
    position: absolute;
  }

  nav .navbar-collapse .dropdown-menu:after {
    content: "";
    display: block;
    top: -11px;
    right: 15px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 11px solid #FFF;
    position: absolute;
  }

  nav .navbar-collapse .dropdown-menu input {
    border: none;
    height: 50px;
    width: 238px;
    float: left;
  }

  nav .navbar-collapse .dropdown-menu button {
    margin: 2px;
    width: 50px;
    height: 40px;
    margin: 5px;
  }

  nav .navbar-collapse .dropdown-menu i {
    position: relative;
    text-align: center;
    padding: 0;
    line-height: 40px!important;
    color: $brand-secondary;
  }

  .section-news_slider img {
    width: 190px;
    margin-left: -10px;
  }

  .section-news_slider .slick-dots {
    text-align: center;
  }

  #sub-page-container .right-content {
    width: 74%;
  }

  #sub-page-container .page-content {
    margin-top: 5px;
  }

  .standort-item .additional-info > button {
    width: auto;
  }

  .section-teaser {
    padding: 15px 0 30px 0;
  }

  .section-teaser .teaser-img {
    height: 160px;
  }

  .section-header_slider .slider--item {
    height: 215px;
  }

  .slider-overlay {
    bottom: 20px;
  }

  .section-header_slider .headline,
  .section-header_slider .sub-headline {
    padding: 5px 10px;
  }

  .section-header_slider .headline {
    font-size: 24px;
  }

  .section-header_slider .sub-headline {
    font-size: 14px;
    margin-top: 5px;
    padding: 8px 10px;
  }

  .section-themepic .heading,
  .section-themepic .text,
  .section-themepic a {
    text-align: left;
    position: relative;
    z-index: 100;
    color: #fff;
    padding: 7px 15px;
  }

  .section-themepic .heading {
    display: inline-block;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 300;
    width: auto;
    min-height: 0;
  }

  .section-themepic .text {
    font-size: 12px;
    padding: 13px 15px;
    width: 375px;
  }

  .section-themepic a {
    margin-top: 15px;
    width: 165px;
    min-height: 35px;
  }

  .themepic-wrapper {
    position: relative;
    height: 250px;
  }

  .themepic-wrapper > .container {
    position: relative;
    height: 250px;
  }

  .themepic-overlay {
    position: absolute;
    bottom: 30px;
    right: 0;
  }

  .section-themepic {
    position: relative;
    padding-bottom: 0;
  }

  #news-slider {
    width: 530px;
    float: left;
  }

  .section-news_slider {
    padding-top: 30px;
  }

  .section-news_slider .pull-right {
    padding: 0;
  }

  .news-slider--image-wrapper {
    width: 160px;
    height: 160px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .news-slider--content {
    padding: 15px;
    width: 370px;
    height: 160px;
  }

  .section-news_slider .slick-dots {
    text-align: left;
    margin-top: -10px;
    left: -5px;
  }

  .section-news_slider .all-news {
    position: relative;
    float: left;
    width: 160px;
    height: 160px;
    margin-left: 30px;
    text-align: left;

  }

  .section-news_slider .all-news span {
    display: block;
    position: absolute;
    bottom: 10px;
    font-size: 24px;
    text-transform: uppercase;
  }

  .section-news_slider .all-news span strong {
    font-size: 42px;
    line-height: 38px;
  }

  .footer-menu {
    margin-top: 30px;
  }

  .footer-logos {
    margin: 20px 0;
  }

  footer .copy {
    text-align: right;
  }

  .section-themepic a {
    border-bottom: 3px solid #0579aa;
  }

  .sub-nav {
    margin-bottom: 30px;
  }

  .sub-nav--infos, .sub-nav--offer {
    display: block;
    background-color: $brand-secondary;
    color: #FFF!important;
    border-bottom: 3px solid #4c3a2e;
    margin-bottom: 15px;
    padding: 5px;
  }

  .sub-nav--infos span, .sub-nav--offer span {
    height: 40px;
    display: block;
    float: left;
    padding: 10px 10px 0 0;
    font-size: 20px;
  }

  .sm-slider--item {
    height: 75px;
  }

  .sm-slider-arrows,
  .sm-slider-arrows .slick-arrow {
    height: 75px;
  }

  .lg-slider--item {
    height: 325px;
  }

  .sub-nav ul ul ul {
    padding-top: 0;
  }

  #navbar-collapse {
    margin: 0;
  }

  section.standortkarte {
    position: relative;
  }

  section.standortkarte svg {
    width: 94%;
    height: 100%;
  }


}

/* Mittlere Geräte (Desktop-PCs, 992px und breiter) */
@media (min-width: $screen-desktop) {

  @-ms-viewport{ width: auto !important; }

   .slider-new {
     margin-bottom: 60px;
     max-height: 472.63px;
     overflow: hidden;
   }


  .sticky-bar {
    display: inline-block;
    position: fixed;
    right: 0;
    top: 270px;
    z-index: 50;
  }

  .sticky-bar a {
    background-color: $brand-secondary;
    color: #fff;
    display: block;
    width: 40px;
    height: 40px;
    margin-bottom: 2px;
    position: relative;
  }

  .sticky-bar a:hover {
    background-color: $brand-primary;
  }

  .sticky-bar a i {
    font-size: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    display: inline-block;
    left: 50%;
  }

  .section-header_slider .slider--item {
    height: 300px;
  }

  .container {
    width: 990px;
  }

  nav .navbar-collapse li {
    padding: 0 5px !important;
  }

  nav .navbar-collapse li:last-child {
    padding: 0!important;
  }

  .section-themepic {
    width: 100%;
  }

  .section-themepic span {
    font-size: 8px;
    vertical-align: middle;
  }

  .section-news_slider {
    width: 100%;
    background-color: $brand-primary;
  }

  .section-news_slider h2 {
    color: #fff;
    text-transform: uppercase;
    text-align: left;
  }

  .section-news_slider #news-slider {
    max-height: 160px;
    margin-bottom: 50px;
  }

  .section-news_slider .slider--item {
    margin-right: 85px;
  }

  .section-news_slider img {
    width: 160px;
    height: 160px;
  }

  .panel-heading .btn-inline {
    float: right;
    margin-top: -22px;
  }

  footer {
    text-align: left;
  }

  #sub-page-container .mail {
    margin-top: 33px;
  }

  #sub-page-container .mail p {
    margin: 3px;
  }

  #sub-page-container .mail b {
    font-size: 14px;
  }

  #standortkarte {
    margin: 20px 10px 0 0;
  }
  .standort-item {
    padding: 15px 25px;
    background: #eee;
    border: 2px solid #eee !important;
    transition: all 0.3s;
  }

  .standort-item .additional-info table tr td:first-of-type {
    background: $brand-secondary;
    border-color: #eee;
    color: #FFF;
  }

  .standort-item.olszowa.active,
  .standort-item.losser.active,
  .standort-item.ochenbruck.active,
  .standort-item.magdeburg.active,
  .standort-item.spelle.active,
  .standort-item.gelsenkirchen.active,
  .standort-item.accordion-item.active {
    border: 2px solid $brand-primary !important;
  }

  circle {
    transition: fill 0.3s;
    fill: $brand-primary;
  }

  circle.olszowa.active,
  circle.ochenbruck.active,
  circle.magdeburg.active,
  circle.losser.active,
  circle.spelle.active,
  circle.gelsenkirchen.active {
    fill: $brand-primary;
  }

  .slider-overlay {
    bottom: 30px;
  }

  #navbar-collapse li a {
    font-size: 18px;
  }

  nav .navbar-brand {
    width: 315px;
    padding: 0;
  }

  .section-header_slider .headline {
    font-size: 32px;
  }

  .section-header_slider .sub-headline {
    font-size: 20px;
  }

  .section-teaser {
    padding: 45px 0 60px 0;
  }

  .section-themepic {
    padding-top: 30px;
  }

  .themepic-wrapper {
    height: 300px;
  }

  .themepic-wrapper>.container {
    height: 300px;
  }

  .section-themepic .text {
    width: 475px;
  }

  .section-themepic .heading {
    font-size: 24px;
  }

  .section-themepic .text {
    font-size: 14px;
  }

  .themepic-overlay {
    bottom: 85px;
    right: 10px;
  }

  #news-slider {
    width: 800px;
  }

  .section-news_slider .news-slider--item {
    margin-right: 30px;
  }

  .section-news_slider .all-news {
    margin-left: 0;
  }

  .news-slider--content {
    width: 210px;
  }

  .footer-logos {
    margin: 0 0 0 40px;
  }

  .section-teaser .teaser-preview {
    height: 340px;
  }

  .section-teaser p {
    font-size: 13px;
  }

  .section-teaser .teaser-text {
    max-height: 58px;
  }

  .sub-nav--infos span, .sub-nav--offer span {
    padding: 10px 15px 0 20px;
  }

  .sub-nav--infos i, .sub-nav--offer i {
    font-size: 15px;
  }

  #sub-page-container .lg-slider {
    width: 475px!important;
    float: left;
    margin-right: 30px;
  }

  #sub-page-container .sm-slider {
    width: 205px;
  }

  #sub-page-container .sm-slider--item {
    margin: 0 0 30px 0;
    height: 100px!important;
  }

  .sm-slider-arrows {
    width: 205px;
    height: 65px;
  }

  .sm-slider-arrows .slick-arrow {
    height: 65px;
  }

  .sm-slider-arrows .slick-arrow i {
    transform: rotate(0deg);
  }

  .lg-slider--item .img-responsive,
  .sm-slider--item .img-responsive {
    max-height: 100%;
  }

  .sm-slider--item {
    border: 2px solid transparent!important;
  }

  .sm-slider--item.slick-current {
    border: 2px solid $brand-primary!important;
  }

  .search-header {
    padding: 50px 30px 50px 100px
  }

  .standort__wrapper {
    position: static;
  }

  .standort-item.click {
    display: none;
  }

  section.standortkarte {
    margin: 50px 0 50px 0;
  }

  section.standortkarte div[class^="col-"] {
    position: static;
  }

  section.standortkarte circle:hover {
    cursor: pointer;
  }

  .standort-item.spelle.click.active {
    display: block;
    position: absolute;
    top: 32%;
    left: 10%;
    width: auto;
  }

  .standort-item.losser.click.active {
    display: block;
    position: absolute;
    top: 32%;
    left: 10%;
    width: auto;
  }

  .standort-item.gelsenkirchen.click.active {
    display: block;
    position: absolute;
    top: 46%;
    left: 8%;
    width: auto;
  }

  .standort-item.magdeburg.click.active {
    display: block;
    position: absolute;
    top: 36.5%;
    left: 33.5%;
    width: auto;
  }

  .standort-item.ochenbruck.click.active {
    display: block;
    position: absolute;
    top: 74.5%;
    left: 31.5%;
    width: auto;
  }

  .standort-item.olszowa.click.active {
    display: block;
    position: absolute;
    top: 59%;
    right: 1.5%;
    width: auto;
  }

  .standort-item.click.active .additional-info>div {
    width: 350px!important;
  }

  .standort-item__close {
    display: block;
    color: #000;
    background: none;
    width: 44px;
    height: 44px;
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    line-height: 44px;
    font-weight: bold;
    font-size: 17px;
  }
  .standort-item:hover {
    cursor: pointer;
  }

}

/* Große Geräte (Desktop-PCs, 1200px und breiter) */
@media (min-width: $screen-lg-desktop) {

  .standort-item.click.active .additional-info>div {
    width: 500px!important;
  }

  .slider-new .lg-slider--item {
    height: 472.63px;
  }

  .container {
    width: 1170px;
  }

  nav img {
    width: 320px;
    padding: 0;
  }

  nav .navbar-brand {
    margin-top: -8px;
  }

  #navbar-collapse {
    margin-top: -6px;
  }

  .navbar-fixed-top.shrink .lower-nav {
    height: 65px;
  }

  nav .navbar-collapse {
    float: right;
  }

  nav .navbar-collapse li {
    padding: 0 17px !important;
  }

  .section-header_slider .slick-prev,
  .section-header_slider .slick-next {
    display: block !important;
    position: absolute;
    border: 1px solid #FFF;
    width: 58px;
    height: 58px;
    top: 73%;
    left: 50%;
  }

  .section-header_slider .slick-prev {
    margin-left: 440px;
    background-image: none;
  }

  .slider-new .slick-prev,
  .slider-new .slick-next {
    display: block !important;
    position: absolute;
    border: 1px solid #FFF;
    width: 58px;
    height: 58px;
    left: 50%;
  }

  .slider-new .slick-prev {
    margin-left: 440px;
    background-image: none;
  }



  .slider-new .slick-prev {
    margin-left: 276px;
    top: 90%;
  }


  .section-header_slider .slick-next,
  .slider-new .slick-next {
    margin-left: 510px;
    background-image: none;
  }

  .section-header_slider .slick-arrow ,
  .slider-new .slick-arrow {
    font-size: 40px;
    color: #FFF;
  }

  .section-themepic {
    background-color: #f7f5f6;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 75px;
  }

  .section-themepic .upper-mask {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .section-themepic .lower-mask {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .section-themepic .upper-mask svg {
    position: relative;
    width: 2560px;
    height: 68px;
    left: 50%;
    transform: translateX(-50%);
  }

  .section-themepic .lower-mask svg {
    position: relative;
    width: 2560px;
    height: 68px;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 120px;
  }

  .section-themepic .upper-fill {
    fill: #FFF;
  }

  .section-themepic .lower-fill {
    fill: $brand-primary;
  }

  .section-news_slider {
    margin-top: -15px;
    padding-bottom: 55px;
  }

  .section-news_slider .slider--item {
    margin-right: 30px;
  }

  .section-news_slider .slick-dots {
    text-align: left;
  }

  #sub-page-container .mail p {
    margin: 2px;
  }

  #sub-page-container .mail b {
    font-size: 17px;
  }

  .section-header_slider .slider--item {
    height: 540px!important;
  }

  #news-slider {
    width: 980px;
    padding-bottom: 0;
    margin-top: 10px;
  }

  .section-news_slider .all-news {
    margin-top: 10px;
  }

  nav .navbar-collapse .dropdown-menu {
    top: 62px;
  }

  .slider-overlay {
    bottom: 110px;
  }

  .section-header_slider {
    margin-bottom: 0;
  }

  .section-header_slider .headline {
    font-size: 35px;
    padding: 3px 15px;
  }

  .section-header_slider .sub-headline {
    font-size: 24px;
    padding: 8px 15px;
  }

  .themepic-wrapper {
    height: 500px;
  }

  .themepic-wrapper>.container {
    height: 500px;
  }

  .svg-wrapper {
    position: absolute;
    bottom: 0;
    height: 68px;
    width: 100%;
    overflow: hidden;
    left: 0;
    right: 0;
  }

  .svg-wrapper.top {
    bottom: auto;
    top: 0;
  }

  .svg-wrapper svg {
    position: relative;
    left: 50%;
    margin-left: -1280px;
    fill: $brand-primary;
  }

  .svg-wrapper.top svg {
    fill: #fff;
  }

  .section-teaser p {
    font-size: 14px;
  }

  .section-teaser .teaser-preview {
    margin-bottom: 0;
  }

  .section-themepic .text {
    width: 530px;
    font-size: 15px;
  }

  .themepic-overlay {
    bottom: 185px;
  }

  .section-themepic a {
    margin-top: 20px;
  }

  .section-themepic .heading {
    font-size: 30px;
  }

  .news-slider--content {
    width: 290px;
  }

  .footer-logos {
    margin: 0 -10px 0 45px;
  }

  footer {
    padding-top: 60px;
  }

  footer .icon-wrapper {
    margin-top: 0;
  }

  footer .copy {
    margin-top: 4px;
    text-align: left;
  }

  footer ul:first-of-type {
    margin-right: 23%;
  }

  .sub-nav--infos, .sub-nav--offer {
    padding: 8px 5px;
  }

  .sub-nav--infos span, .sub-nav--offer span {
    padding: 10px 20px 0 20px;
    font-size: 24px;
  }

  .sub-nav--infos i, .sub-nav--offer i {
    font-size: 16px;
  }

  #sub-page-container .lg-slider {
    width: 554px!important;
  }

  .lg-slider--item {
    height: 365px;
  }

  #sub-page-container .sm-slider {
    width: 259px;
  }

  #sub-page-container .sm-slider--item {
    height: 130px!important;
  }

  .sm-slider-arrows {
    width: 259px;
    height: 45px;
  }

  .sm-slider-arrows .slick-arrow {
    width: 49%;
    height: 45px;
  }

}

.section-header_slider .slick-active img {
  overflow: hidden!important;
  z-index: 2!important;
}

.slider-overlay {
  z-index: 3!important;
}

.accordion{
  position: relative;
  height: auto;
  margin: 10px auto;
}

.accordion-item{
  position: relative;
  width: 100%;
  height: auto;
  cursor: pointer;
}
.arrow-down {
  float: right;
  margin-top: 2px;
  width: 17px;
  height:17px;
  transition: all 500ms;
  transform: rotate(0deg);
  fill: #5e5148;
}

.arrow-rotate {
  transform: rotate(180deg) !important;
}
.accordion-item__title {
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  color: #5e5148;
  font-weight: 600;
  border-bottom: 1px solid #ddd;
  -webkit-transition:all 0.2s linear;
  -moz-transition:all 0.2s linear;
  transition:all 0.2s linear;
}

.accordion-active{
}

.accordion-content{
  background-color: #eeeef1;
  border-bottom: 1px solid #ddd;
  display:none;
  padding: 10px 15px
}

.accordion-btn {
  display: block;
}

#sub-page-container .breadcrumb {
  visibility: hidden!important;
}

.stellenanzeigen-component table {
  max-width: 100%;
}


@media (min-width: 1900px) {
  .section-teaser {
    padding: 0 0 0 0;
  }
}

@media (min-width: 1200px) {
  .right-content .section-iconstrip .col-lg-2 {
    width: 30.66667%!important;
  }
}

/** AK **/
@import "connectiv/garage-configurator.scss";


