.db-form {

  background-color: #eee;
  padding: 20px 20px 30px 20px;
  max-width: 600px;
  border-bottom: 5px #4c3a2e solid;

  .status,
  .addition {
    margin-left: 10px;
    margin-top: 6px;
    float: left;
    margin-bottom: 10px;
  }

  .form-row {
    margin-bottom: 7px;
    clear: both;
    height: 60px;
  }

  .field {
    width: 320px;
    max-width: 100%;
    float: left;
  }

  .label {
    margin-top: 10px;
  }

  .input {
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    &:focus {
      border-color: #009de0;
      outline: 0;
    }
  }

  .required.valid {
    border-color: #3c763d;
  }

  .required.error {
    border-width: 1px;
    border-style: solid;
    border-color: #a94442;
    + label {
      color: #a94442;
    }
  }

  .input-textarea {
    height: auto!important;
  }

  .checkbox {
    float: left;
    margin-right: 5px;
  }

  .error {
    font-size: 12px;
    font-weight: 400;
  }

  select.error {
    font-size: inherit;
  }

  .separator {
    border-top: 1px solid #ccc;
  }

}

.db-form--randleiste {
  padding: 20px!important;
}

.db-form--randleiste .status > .label:empty {
  display: none;
}

#stellenanzeige_form.db-form .field {
  width: 480px;
}

.db-form-wide {
  width: 100%;
  max-width: unset;
  padding: 10px 0px;
}

#stellenanzeige_form.db-form-wide .field {
  width: 100%;
}

.db-form-wide .form-row {
  height: unset;
}

.db-form-wide .field,
.db-form-wide .status {
  float: none;
}

.db-form-wide .field,
.db-form-wide .field.field-noshadow .input-textarea {
  box-shadow: 3px 3px 10px rgba(0,0,0,0.29) !important;
}

.db-form-wide .field.field-noshadow {
  box-shadow: none !important;
}

.db-form-wide .input-group-addon {
  background-color: #fff;
  border: none;
  border-radius: 0;
  cursor: pointer;
}

.db-form-wide .input,
.db-form-wide input.input {
  border: none;
  height: 38px;
}

.custom-file-input {
  font-weight: normal;
  cursor: pointer;
}

.db-form-wide .status .error {
  border-color: #a94442;
  + label {
    color: #a94442;
  }
}

.custom-file-input__box {
  background-color: #009de0;
  color: #fff;
  padding: 7px 15px;
  display: inline-block;
}

.custom-file-input__name {
  padding: 7px 15px;
  color: #848484;
}

.custom-file-input + input[type="file"] {
  display: none;
}

@media (min-width: $screen-tablet) {

  .db-form {
    background-color: #eee;
    padding: 20px 40px 40px 40px;
    max-width: 600px;
  }

  .db-form-wide {
    max-width: unset;
    width: 100%;
    padding: 10px 0px;
  }

}